body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  background-color: #4B0082;
  color: #ffffff;
}

h1, h2, h3, h4 {
  color: #FFD700;
}

button {
  background-color: #40E0D0;
  color: #4B0082;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

button:hover {
  background-color: #FFD700;
  color: #ffffff;
}
